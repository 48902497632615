<template>
  <div class="flex flex-col justify-center items-center w-full h-screen">
    <div>
      <h2 class="font-main font-bold text-3xl md:text-xl lg:text-xl">
        Réinitialisez
      </h2>
      <p class="text-base font-normal text-promy-gray-250 mt-2">
        Changez votre mot de passe
      </p>
      <div class="reset-form mt-10">
        <ValidationObserver
          tag="form"
          v-slot="{ invalid }"
          @submit.prevent="submitEmailResetForm"
        >
          <pro-input
            label="Email"
            :placeholder="'Email'"
            :rules="'required|email'"
            v-model="email"
            type="email"
          />
          <div class="flex flex-col mt-8 space-y-4">
            <pro-button
              type="submit"
              :disabled="invalid"
              class="rounded-lg"
              btn="primary"
            >
              <span>Réinitialisez</span>
            </pro-button>
            <pro-button
              @click="navigateLogin"
              type="button"
              class="py-4 px-40 sm:px-36 rounded-lg border-none"
              btn="secondary"
            >
              <span>Connexion</span>
            </pro-button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
    }
  },
  methods: {
    navigateLogin() {
      this.$router.push({ name: 'Login' })
    },
    async submitEmailResetForm() {
      try {
        const response = await this.$http.post(
          'grand-publics/forgot-password',
          { email: this.email },
        )
        this.toast(
          'Réinitialisation du mot de passe',
          response.data.message,
          'success',
        )
      } catch (error) {
        this.toast(
          'Réinitialisation du mot de passe',
          "quelque chose s'est mal passé ou l'e-mail n'existe pas",
          'error',
        )
      }
    },
  },
}
</script>

<style scoped>
.reset-form {
  width: 420px;
}
@media (max-width: 768px) {
  .reset-form {
    width: 350px;
  }
}
</style>
